<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">背景執行緒</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->

        <el-row type="flex" :gutter="20">
          <el-col :span="19">
            <div class="grid-content bg-purple">
              <el-button
                style="margin-left: 10px"
                size="small"
                type="success"
                @click="restartQueue"
                >重置背景執行緒</el-button
              >
            </div>
          </el-col>
          <el-col :span="1">
            <div class="grid-content bg-purple">
              <el-button
                style="margin-left: 10px"
                size="small"
                type="danger"
                @click="handleDeleteAll()"
                >移除所有背景執行緒</el-button
              >
            </div></el-col
          >
        </el-row>
      </card>
      <!-- end card -->
    </div>
    <div class="col-md-12">
      <h4 class="title">背景執行緒列表</h4>
      <p class="category"></p>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category"></div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column
              :min-width="30"
              fixed="right"
              class-name="td-actions"
              label="功能"
            >
              <template slot-scope="props">
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.currentPage"
            @change-page="handleCurrentChange"
            :per-page="pagination.perPage"
            @per-page="handleSizeChange"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Row,
  Col,
} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
export default {
  components: {
    PPagination,
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "id",
          label: "序號",
        },
        {
          prop: "sn",
          label: "裝置",
        },
        {
          prop: "cmd",
          label: "指令",
        },
        {
          prop: "user_id",
          label: "使用者 ID",
        },
        {
          prop: "user_name",
          label: "使用者名稱",
        },
        {
          prop: "status",
          label: "狀態",
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const limit = this.pagination.perPage;
      const index = this.pagination.currentPage;

      axios.get(`/face/queue?limit=${limit}&index=${index}`).then((res) => {
        this.tableData = res.data.rows;
        this.pagination.total = res.data.count;
      });
    },
    handleSizeChange(size) {
      this.pagination.perPage = size;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getData();
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "是否移除",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/face/queue?id=${row.id}`).then(
              (res) => {
                this.$notify({
                  title: "背景執行緒刪除成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
                swal.fire("背景執行緒刪除成功!", "", "success");
                this.timeOutReload = setTimeout(() => {
                  this.getData();
                }, 500);
              },
              (res) => {
                this.$notify({
                  title: "背景執行緒刪除失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
                swal.fire("背景執行緒刪除失敗", "", "error");
              }
            );
          } else if (result.isDenied) {
            swal.fire("背景執行緒尚未移除", "", "info");
          }
        });
    },
    handleDeleteAll(index, row) {
      swal
        .fire({
          title: "是否移除所有背景執行緒，此操作可能導致系統錯誤！",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/face/queues`).then(
              (res) => {
                this.$notify({
                  title: "所有背景執行緒刪除成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
                swal.fire("所有背景執行緒刪除成功!", "", "success");
                this.timeOutReload = setTimeout(() => {
                  this.getData();
                }, 500);
              },
              (res) => {
                this.$notify({
                  title: "所有背景執行緒刪除失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
                swal.fire("所有背景執行緒刪除失敗", "", "error");
              }
            );
          } else if (result.isDenied) {
            swal.fire("所有背景執行緒尚未移除", "", "info");
          }
        });
    },
    async restartQueue() {
      swal
        .fire({
          title: "是否重置背景執行緒",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.get(`/face/restartQueueInterval`).then(
              (res) => {
                this.$notify({
                  title: "重置背景執行緒成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
                swal.fire("重置背景執行緒成功!", "", "success");
                this.timeOutReload = setTimeout(() => {
                  this.getData();
                }, 500);
              },
              (res) => {
                this.$notify({
                  title: "重置背景執行緒失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
                swal.fire("重置背景執行緒失敗", "", "error");
              }
            );
          } else if (result.isDenied) {
            swal.fire("所有偵測觸發紀錄尚未移除", "", "info");
          }
        });
    },
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
